// 3rd-party Plugins
// @import '~highlight.js/styles/googlecode.css';
// @import '~perfect-scrollbar/css/perfect-scrollbar.css';
// @import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~react-toastify/dist/ReactToastify.css';
// @import '~antd/dist/antd.css';

// Custom styles
@import './assets/styles/tabs.scss';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

@import './_metronic/_assets/sass/vendors/plugins/_toastr.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
	@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
	@import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@import './_metronic/_assets/sass/vendors/plugins/bootstrap-datepicker.scss';
@import './_metronic/_assets/sass/vendors/plugins/bootstrap-timepicker.scss';

body {
	--black: #000;
	--gray-100: #f1f3f5;
	--gray-200: #e9ecef;
	--gray-300: #dee2e6;
	--gray-400: #ced4da;
	--gray-500: #adb5bd;
	--gray-600: #868e96;
	--gray-700: #495057;
	--gray-800: #343a40;

	--details: #fff;
	--bg: var(--gray-100);

	&.dark-mode {
		--details: #1e1e1e;
		--bg: #151515;
		--gray-100: #151515;
		--gray-500: #343a40;
		--gray-700: #dee2e6;
		--gray-800: #f1f3f5;
		--black: #fff;
	}
}

.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;

		@import './_metronic/_assets/sass/vendors/plugins/bootstrap-datepicker';
		@import './_metronic/_assets/sass/vendors/plugins/bootstrap-timepicker';
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}

html,
body {
	font-family: Poppins, Helvetica, 'sans-serif' !important;
	height: 100vh;
	background-color: var(--bg);
}

h6 {
	font-size: 1rem !important;
}

.btn-light-dark {
	color: #464e5f !important;
	background-color: #e5eaee !important;
}

.btn-light-dark:hover {
	color: #fff !important;
	background-color: #464e5f !important;
}

.card {
	box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.btn-bg-transparent {
	border: 0 !important;
	background-color: transparent !important;
}

.btn.btn-primary.dropdown-toggle.no-arrow::after {
	display: none;
}

.ck.ck-editor__editable_inline {
	min-height: 210px;
	border-radius: 0.42rem !important;
}

.ck-focused {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.opacity-50:hover,
.opacity-50:active,
.opacity-50:focus {
	opacity: 1 !important;
}

.ant-picker-time-panel-column {
	overflow-y: scroll !important;
	scrollbar-width: thin;
}

.svg-icon.svg-icon-primary svg g [fill] {
	fill: #5e9fd2 !important;
}

.label-outline-primary {
	color: #5e9fd2 !important;
	border: 1px solid #5e9fd2 !important;
}

.text-primary {
	color: #5e9fd2 !important;
}

.text-white {
	color: #a2a3b7 !important;
}

.MuiOutlinedInput-root {
	border-radius: 6px !important;
}

select.form-control {
	border-radius: 6px !important;
	background: none !important;
	border-color: var(--gray-500) !important;
	color: var(--gray-800) !important;

	option {
		background: var(--details) !important;
		color: var(--gray-800) !important;
	}
}

.text-gray-800 {
	color: var(--gray-800) !important;
}

.text-gray-700 {
	color: var(--gray-700) !important;
}

.text-gray-600 {
	color: var(--gray-600) !important;
}

.text-gray-500 {
	color: var(--gray-500);
}

.bg-gray-100 {
	background-color: var(--gray-100) !important;
}

.bg-hover-scaleup {
	transition: all 0.25s;
	&:hover {
		transform: scale(1.05);
		transition: all 0.25s;
	}
}

.MuiCard-root {
	box-shadow: none !important;
	background: var(--details) !important;
}

.checkout-grid {
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	gap: 32px;

	@media (max-width: 968px) {
		grid-template-columns: 1fr;

		.form-grid-item {
			grid-row: 2 / 3;
		}

		.sidebar-grid-item {
			grid-row: 1 / 2;
		}
	}
}

.border-gray-500 {
	border-color: var(--gray-500) !important;
}

.border.border-gray-600 {
	border-color: var(--gray-600) !important;

	&:hover {
		border-color: var(--gray-600) !important;
		color: var(--gray-600) !important;
	}
}

.bg-white {
	background-color: var(--details) !important;
}

// Input border color
.MuiOutlinedInput-notchedOutline {
	border-color: var(--gray-500) !important;
}

.MuiTextField-root {
	.MuiInputLabel-root {
		color: var(--gray-600) !important;
	}

	.MuiInputBase-input {
		color: var(--black) !important;

		&::placeholder {
			color: var(--gray-200) !important;
		}
	}
}

.nav-link.active {
	background: var(--bg) !important;
}
