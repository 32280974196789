//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary: #3699ff; // Bootstrap variable
$primary-hover: #187de4; // Custom variable
$primary-light: #e1f0ff; // Custom variable
$primary-inverse: #ffffff; // Custom variable
