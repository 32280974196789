.nav.nav-bordered.nav-tabs {
	border-bottom: none;

	.nav-link {
		border-radius: 4px;
	}

	.nav-link.active {
		border: 2px solid #3699ff;

		.svg-icon svg g [fill] {
			fill: #3699ff !important;
		}
	}
}
